import { useMemo, CSSProperties } from 'react'
import Select, {
	components,
	InputActionMeta,
	StylesConfig,
	Theme,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { createGuid } from '../lib/utils'
import useTranslation from '../lib/lang/useTranslation'
import {
	GrayStatusIcon,
	GreenStatusIcon,
	RedStatusIcon,
	YellowStatusIcon,
} from '@app/assets/icons'
import { Box } from '@mui/material'
import { themeColors } from '@app/constants'

export interface CustomSelectProps {
	creatable?: boolean
	clearable?: boolean
	disabled?: boolean
	multiple?: boolean
	styles?: CSSProperties
	onInputChange?:
		| ((newValue: string, actionMeta: InputActionMeta) => void)
		| undefined
	options: any[] | undefined
	onCreate?: (inputValue: string) => void
	onChange?:
		| (((value: any, actionMeta: any) => void) &
				((value: any, action: any) => void))
		| undefined
	placeholder?: string
	selected?: any
	labelTransform?: (option: any) => string
	labelKey?: string
	valueKey?: string
	id?: string
	name?: string
	size?: 'small' | 'large'
	className?: any
	filterOption?: () => boolean
	width?: string | number
}

export default function CustomSelect({
	clearable,
	creatable,
	disabled,
	labelTransform,
	labelKey = 'label',
	name,
	multiple = false,
	options,
	onCreate,
	onChange,
	onInputChange,
	id,
	styles = {},
	placeholder = 'shared.defaultSelectPlaceholder',
	selected = null,
	size = 'large',
	valueKey = 'value',
	className,
	width,
	filterOption,
}: CustomSelectProps) {
	const height = size === 'small' ? 36 : 51

	const customStyles: StylesConfig<any, false> = {
		valueContainer: (base) => ({
			...base,
			height: height,
			...(width && {
				width: width,
				// minWidth: width,
			}),
		}),
		menuPortal: (base) => ({
			...base,
			zIndex: 9999,
		}),
		control: (base, { isDisabled }) => ({
			...base,
			...styles,
			...(width && {
				width: width,
				// minWidth: width,
			}),
			backgroundColor: disabled
				? themeColors.white
				: base.backgroundColor,
			borderColor: '#00000044',
			'&:hover': {
				borderColor: themeColors.agitronDark,
			},
			padding: '0px',
			// ...(isDisabled && {
			// 	backgroundColor: themeColors.backgroundColor,
			// }),
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
		clearIndicator: (base) => ({
			...base,

			padding: 0,
		}),
		dropdownIndicator: (base, { isDisabled }) => ({
			...base,
			// ...(isDisabled && {
			// 	display: 'none',
			// }),
			padding: 0,
			paddingRight: '12px',
			paddingLeft: '8px',
		}),
		menuList: (base) => {
			return {
				...base,
				maxHeight: 300,
			}
		},
		option: (base, { isDisabled, isFocused, isSelected, hasValue }) => {
			let backgroundColor = base.backgroundColor
			let color = base.color

			if (isFocused && !isSelected) {
				backgroundColor = '#f9f9f9'
			}
			// if (isDisabled) {
			// 	color = '#adacac'
			// }

			return {
				...base,
				backgroundColor: backgroundColor,
				color: color,
			}
		},
	}

	const { t } = useTranslation()
	const inputId = useMemo(() => id || createGuid(), [])

	placeholder = useMemo(() => t(placeholder), [placeholder])

	const formatCreateLabel = (label: string) => {
		return t('shared.createLabel', {
			label,
		})
	}

	const getSelectTheme = (theme: Theme): Theme => {
		return {
			...theme,
			borderRadius: 4,
			spacing: {
				baseUnit: size === 'large' ? 6 : 4,
			} as any,
			colors: {
				...theme.colors,
				primary25: 'lightgrey',
				primary: '#1b2c36',
			},
		}
	}

	const onCreateOption = (inputValue: string) => {
		if (onCreate) {
			onCreate(inputValue)
		}
	}

	const sxBox = {
		fontSize: '12px',
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
		height: size === 'large' ? height - 22 : height - 4,
	}

	const { Option, SingleValue, Placeholder } = components
	const IconOption = (props: any) => (
		<Option {...props}>
			<Box sx={sxBox}>
				{props.label == 'Running' ||
				props.label == 'Success' ||
				props.label == 'Complete' ||
				props.label == 'Online' ? (
					<GreenStatusIcon />
				) : props.label == 'Refreshing' ||
				  props.label == 'Processing' ||
				  props.label == 'Need attention' ? (
					<YellowStatusIcon />
				) : props.label == 'Error' || props.label == 'Offline' ? (
					<RedStatusIcon />
				) : props.label == 'Registered' ||
				  props.label == 'All transactions' ||
				  props.label == 'All statuses' ? (
					<GrayStatusIcon />
				) : null}
				{props.label}
			</Box>
		</Option>
	)

	const IconSelected = (props: any) => (
		<SingleValue {...props}>
			<Box sx={sxBox}>
				{props.data.label == 'Running' ||
				props.data.label == 'Success' ||
				props.data.label == 'Complete' ||
				props.data.label == 'Online' ? (
					<GreenStatusIcon />
				) : props.data.label == 'Refreshing' ||
				  props.data.label == 'Processing' ||
				  props.data.label == 'Need attention' ? (
					<YellowStatusIcon />
				) : props.data.label == 'Error' ||
				  props.data.label == 'Offline' ? (
					<RedStatusIcon />
				) : props.data.label == 'Registered' ||
				  props.data.label == 'All transactions' ||
				  props.data.label == 'All statuses' ? (
					<GrayStatusIcon />
				) : null}
				{props.data[labelKey]}
			</Box>
		</SingleValue>
	)

	const PlaceholderWithIcon = (props: any) => (
		<Placeholder {...props}>
			<Box sx={sxBox}>
				{placeholder == 'All Statuses' ||
				placeholder == 'All transactions' ? (
					<GrayStatusIcon />
				) : null}
				{placeholder}
			</Box>
		</Placeholder>
	)

	if (creatable) {
		return (
			<CreatableSelect
				menuPortalTarget={document.body}
				value={selected}
				isClearable={clearable}
				name={name}
				inputId={inputId}
				instanceId={1}
				menuPlacement="auto"
				formatCreateLabel={formatCreateLabel}
				onInputChange={onInputChange}
				isDisabled={disabled}
				isMulti={multiple as any}
				styles={customStyles}
				options={options}
				onCreateOption={onCreateOption}
				onChange={onChange}
				getOptionLabel={(option) => option[labelKey]}
				getOptionValue={(option) => option[valueKey]}
				theme={getSelectTheme}
				components={{
					Option: IconOption,
					SingleValue: IconSelected,
					Placeholder: PlaceholderWithIcon,
				}}
				isOptionDisabled={(option) => {
					return option.isDisabled
				}}
			/>
		)
	}

	if (!labelTransform) {
		labelTransform = (option: any) => option[labelKey]
	}

	return (
		<Select
			menuPortalTarget={document.body}
			filterOption={filterOption}
			value={selected}
			isClearable={clearable}
			name={name}
			inputId={inputId}
			instanceId={1}
			menuPlacement="auto"
			isMulti={multiple as any}
			onInputChange={onInputChange}
			isDisabled={disabled}
			styles={customStyles}
			options={options}
			onChange={onChange}
			getOptionLabel={labelTransform}
			getOptionValue={(option) => option[valueKey]}
			theme={getSelectTheme}
			className={className}
			components={{
				Option: IconOption,
				SingleValue: IconSelected,
				Placeholder: PlaceholderWithIcon,
			}}
			isOptionDisabled={(option) => {
				if (option.isDisabled) return true
				else return false
			}}
		/>
	)
}
