//if changing this add support on the reports page
export enum Currencies {
	EUR = 'EUR',
	USD = 'USD',
	GBP = 'GBP',
	CHF = 'CHF',
}

export enum Unit {
	Pcs = 'PCS',
}

export enum HistoryType {
	PLUS = 'PLUS',
	MINUS = 'MINUS',
	CREATED = 'CREATED',
}
